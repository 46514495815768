import React from 'react';
import LogoImg from '../assets/img/SarTec Logo HR cropped1.png';

function Navbar({ toggleSidebar }) {
    return (
        <nav className="bg-gray-900 text-white p-4 z-10">
            <div className="flex justify-between items-center">
                <button onClick={toggleSidebar} className="md:hidden">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>
                <div className="flex items-center">
                <img src={LogoImg} alt="Logo" className="w-28 lg:w-36 mr-2" />
                <div className="text-xl hidden">SarTec Web App</div>
        </div>
            </div>
        </nav>
    );
}

export default Navbar;
