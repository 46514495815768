// import React, { useState, useEffect } from 'react';
// import ReactApexChart from 'react-apexcharts';

// const InventoryGainGraph = ({ fieldsData }) => {
//   const [seriesData, setSeriesData] = useState({
//     series: [
//       {
//         name: 'Inventory Gain (%)',
//         data: [],
//       },
//     ],
//     options: {
//       chart: {
//         type: 'area',
//         background: 'transparent',
//         height: 350,
//         zoom: {
//           enabled: true,
//           type: 'x', // Enable zooming along the x-axis
//           autoScaleYaxis: true, // Adjust y-axis automatically when zooming
//         },
//         toolbar: {
//           show: true,
//           tools: {
//             download: true,
//             selection: true,
//             zoom: true,
//             zoomin: true,
//             zoomout: true,
//             pan: true,
//             reset: true,
//           },
//         },
//         animations: {
//           enabled: true,
//           easing: 'easeinout',
//           speed: 800,
//           animateGradually: {
//             enabled: true,
//             delay: 150,
//           },
//           dynamicAnimation: {
//             enabled: true,
//             speed: 350,
//           },
//         },
//         events: {
//           beforeResetZoom: (ctx, opt) => {
//             if (seriesData.series[0]?.data.length > 15) {
//               return {
//                 xaxis: {
//                   min: 0,
//                   max: 9,
//                 },
//               };
//             } else {
//               return {};
//             }
//           },
//         },
//       },
//       colors: ['#F88945'],
//       xaxis: {
//         categories: [],
//         tickPlacement: "on",
//         labels: {
//           style: {
//             colors: '#EAEAEA', // Light color for the text
//           },
//         },
//       },
//       yaxis: {
//         labels: {
//           style: {
//             colors: '#EAEAEA',
//           },
//           formatter: (value) => {
//             return `${value.toFixed(3)}%`;
//           },
//         },
//       },
      
//       title: {
//         // text: 'Inventory Gain (%) per Batch',
//         style: {
//           color: '#EAEAEA', // Light color for the text
//         },
//       },
//       tooltip: {
//         theme: 'dark', // Set tooltip theme to dark
//       },
//       responsive: [{
//         breakpoint: 1000,
//         options: {
//           chart: {
//             height: 300
//           },
//           xaxis: {
//             labels: {
//               style: {
//                 fontSize: '12px'
//               }
//             }
//           },
//           yaxis: {
//             title: {
//               text: 'Value',
//             },
//             labels: {
//               style: {
//                 fontSize: '12px'
//               }
//             }
//           }
//         }
//       }]
//     },
//   });

//   // Function to sort batch IDs correctly
//   const sortBatches = (data) => {
//     return data.sort((a, b) => {
//       const aParts = a.id.split('_');
//       const bParts = b.id.split('_');
//       const aNum = parseInt(aParts[aParts.length - 1], 10);
//       const bNum = parseInt(bParts[bParts.length - 1], 10);
//       return aNum - bNum;
//     });
//   };

//   useEffect(() => {
//     const sortedData = sortBatches([...fieldsData]);
//     const dataSeries = sortedData.map((batch) => {
//       const { LSCAdded, WaterAdded, Grain } = batch;
//       const lscLbs = (LSCAdded / 128) * 9.18;
//       const waterLbs = WaterAdded * 8.3;
//       const totalLbs = lscLbs + waterLbs;
//       const inventoryGainPercentage = Grain > 0 ? ((totalLbs / Grain) * 100).toFixed(3) : 0;
//       return {
//         x: batch.id,
//         y: inventoryGainPercentage,
//       };
//     });

//     setSeriesData((prevData) => ({
//       ...prevData,
//       series: [{ name: 'Inventory Gain (%)', data: dataSeries }],
//       options: {
//         ...prevData.options,
//         xaxis: {
//           ...prevData.options.xaxis,
//           categories: dataSeries.map(data => data.x),
//         }
//       }
//     }));
//   }, [fieldsData]);

//   return (
//     <div className="w-full h-full">
//       <div className="w-full">
//         <h3 className='text-gray-200'>Inventory Gain (%) per Batch</h3>
//         <ReactApexChart options={seriesData.options} series={seriesData.series} type="area" height={350} />
//       </div>
//     </div>
//   );
// };

// export default InventoryGainGraph;


import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const InventoryGainGraph = ({ fieldsData }) => {
  const [seriesData, setSeriesData] = useState({
    series: [
      {
        name: 'Inventory Gain (%)',
        data: [],
      },
    ],
    options: {
      chart: {
        type: 'area',
        background: 'transparent',
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: true,
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        events: {
          beforeResetZoom: (ctx, opt) => {
            if (seriesData.series[0]?.data.length > 15) {
              return {
                xaxis: {
                  min: 0,
                  max: 9,
                },
              };
            } else {
              return {};
            }
          },
        },
      },
      colors: ['#F88945'],
      xaxis: {
        categories: [],
        tickPlacement: "on",
        labels: {
          style: {
            colors: '#EAEAEA',
          },
        },
        title: {
          text: 'Batch Number',
          style: {
            color: '#EAEAEA',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#EAEAEA',
          },
          formatter: (value) => {
            return `${value.toFixed(3)}%`;
          },
        },
        title: {
          text: 'Inventory Gain (%)',
          style: {
            color: '#EAEAEA',
          },
        },
      },
      tooltip: {
        theme: 'dark',
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              height: 300,
            },
            xaxis: {
              labels: {
                style: {
                  fontSize: '12px',
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: '12px',
                },
              },
            },
          },
        },
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 250,
            },
            xaxis: {
              labels: {
                style: {
                  fontSize: '10px',
                },
              },
              title: {
                style: {
                  fontSize: '12px',
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: '10px',
                },
              },
              title: {
                style: {
                  fontSize: '12px',
                },
              },
            },
          },
        },
      ],
    },
  });

  const sortBatches = (data) => {
    return data.sort((a, b) => {
      const aParts = a.id.split('_');
      const bParts = b.id.split('_');
      const aNum = parseInt(aParts[aParts.length - 1], 10);
      const bNum = parseInt(bParts[bParts.length - 1], 10);
      return aNum - bNum;
    });
  };

  useEffect(() => {
    const sortedData = sortBatches([...fieldsData]);
    const dataSeries = sortedData.map((batch) => {
      const { LSCAdded, WaterAdded, Grain } = batch;
      const lscLbs = (LSCAdded / 128) * 9.18;
      const waterLbs = WaterAdded * 8.3;
      const totalLbs = lscLbs + waterLbs;
      const inventoryGainPercentage = Grain > 0 ? ((totalLbs / Grain) * 100).toFixed(3) : 0;
      return {
        x: batch.id.removeChar(),
        y: inventoryGainPercentage,
      };
    });

    setSeriesData((prevData) => ({
      ...prevData,
      series: [{ name: 'Inventory Gain (%)', data: dataSeries }],
      options: {
        ...prevData.options,
        xaxis: {
          ...prevData.options.xaxis,
          categories: dataSeries.map(data => data.x),
        },
      },
    }));
  }, [fieldsData]);

  return (
    <div className="w-full h-full">
      <div className="w-full">
        <h3 className="text-gray-200">Inventory Gain (%) per Batch</h3>
        <ReactApexChart options={seriesData.options} series={seriesData.series} type="area" height={350} />
      </div>
    </div>
  );
};

String.prototype.removeChar = function () {
  var tmp = this.split('_'); // convert to an array
 // extract batch number from batch id
  return tmp[1]; // reconstruct the string
}

export default InventoryGainGraph;
