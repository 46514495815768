// // src/components/ProtectedRoute.js
// import React, { useState, useEffect } from 'react';
// import { Navigate } from 'react-router-dom';
// import { onAuthStateChanged } from 'firebase/auth';
// import { auth } from '../firebase';

// const ProtectedRoute = ({ component: Component, ...rest }) => {
//     const [loading, setLoading] = useState(true);
//     const [authenticated, setAuthenticated] = useState(false);

//     useEffect(() => {
//         const unsubscribe = onAuthStateChanged(auth, (user) => {
//             setAuthenticated(!!user);
//             setLoading(false);
//         });

//         return () => unsubscribe();
//     }, []);

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     return authenticated ? <Component {...rest} /> : <Navigate to="/login" />;
// };

// export default ProtectedRoute;

import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthenticated(true);
            } else {
                setAuthenticated(false);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return authenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;

