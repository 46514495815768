import React from 'react';
import '../assets/LoadingAnimation.css';

const LoadingAnimation = () => {
  return (
    <div class="loading-container">
    <div class="bouncing-dots-container">
      <div class="bouncing-dot"></div>
      <div class="bouncing-dot"></div>
      <div class="bouncing-dot"></div>
    </div>
    <div class="loading-text">Loading</div>
  </div>
  );
};

export default LoadingAnimation;
