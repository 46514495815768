// // src/ContactPage.js
// import React, { useState } from 'react';
// import axios from 'axios';

// const ContactPage = () => {
//     const [name, setName] = useState('');
//     const [email, setEmail] = useState('');
//     const [company, setCompany] = useState('');
//     const [phone, setPhone] = useState('');
//     const [subject, setSubject] = useState('');
//     const [message, setMessage] = useState('');
//     const [status, setStatus] = useState('');

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post('http://localhost:8000/api/contact/', {
//                 name,
//                 email,
//                 message,
//             });
//             if (response.status === 200) {
//                 setStatus('Message sent successfully');
//                 setName('');
//                 setEmail('');
//                 setMessage('');
//             } else {
//                 setStatus('Failed to send message');
//             }
//         } catch (error) {
//             setStatus('Failed to send message');
//         }
//     };

//     return (
//         <div className="min-h-screen bg-gray-100 flex items-center justify-center">
//             <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
//                 <h2 className="text-2xl mb-6">Contact Us</h2>
//                 {status && <div className="mb-4 text-green-600">{status}</div>}
//                 <form onSubmit={handleSubmit}>
//                     <div className="mb-4">
//                         <label className="block text-gray-700">Name</label>
//                         <input
//                             type="text"
//                             value={name}
//                             onChange={(e) => setName(e.target.value)}
//                             className="w-full px-3 py-2 border rounded"
//                             required
//                         />
//                     </div>
//                     <div className="mb-4">
//                         <label className="block text-gray-700">Email</label>
//                         <input
//                             type="email"
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                             className="w-full px-3 py-2 border rounded"
//                             required
//                         />
//                     </div>
//                     <div className="mb-4">
//                         <label className="block text-gray-700">Message</label>
//                         <textarea
//                             value={message}
//                             onChange={(e) => setMessage(e.target.value)}
//                             className="w-full px-3 py-2 border rounded"
//                             required
//                         />
//                     </div>
//                     <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded">
//                         Send
//                     </button>
//                 </form>
//             </div>
//         </div>
//     );
// };

// export default ContactPage;



// // src/ContactPage.js
// import React, { useState } from 'react';
// import axios from 'axios';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ContactImg from '../assets/img/subtle-cinematics-V4Mo8UYKRvY-unsplash.jpg';

// const ContactPage = () => {
//     const [name, setName] = useState('');
//     const [email, setEmail] = useState('');
//     const [company, setCompany] = useState('');
//     const [phone, setPhone] = useState('');
//     const [subject, setSubject] = useState('');
//     const [message, setMessage] = useState('');
//     const [status, setStatus] = useState('');

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post('http://localhost:8000/api/contact/', {
//                 name,
//                 email,
//                 company,
//                 phone,
//                 subject,
//                 message,
//             });
//             if (response.status === 200) {
//                 setStatus('Message sent successfully');
//                 setName('');
//                 setEmail('');
//                 setCompany('');
//                 setPhone('');
//                 setSubject('');
//                 setMessage('');
//             } else {
//                 setStatus('Failed to send message');
//             }
//         } catch (error) {
//             setStatus('Failed to send message');
//         }
//     };

//     return (
//         <div className="min-h-fit flex-col lg:flex items-center justify-center">
//             <div className="absolute inset-0 bg-cover bg-center opacity-80 lg:hidden" style={{ backgroundImage: `url(${ContactImg})` }}></div>
//             <div className="bg-white p-4 rounded-lg opacity-90 shadow-md w-full max-w-1/2 lg:w-9/12 lg:flex lg:items-center lg:opacity-100">
//                 <div className="w-full lg:w-1/2 p-4 lg:mb-10">
//                     <div className="mb-6 text-center">
//                         <h1 className="text-3xl font-bold mb-2 text-gray-800">Contact Us</h1>
//                         <p className="text-lg text-gray-600">We'd love to hear from you</p>
//                     </div>
//                     {status && <div className="mb-4 text-green-600">{status}</div>}
//                     <form onSubmit={handleSubmit} className="space-y-4">
//                         <div className="flex flex-wrap -mx-2">
//                             <div className="w-full lg:w-1/2 px-2 mb-4">
//                                 <label className="block text-sm font-medium text-gray-700">Name</label>
//                                 <input
//                                     type="text"
//                                     value={name}
//                                     onChange={(e) => setName(e.target.value)}
//                                     className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                                     required
//                                 />
//                             </div>
//                             <div className="w-full lg:w-1/2 px-2 mb-4">
//                                 <label className="block text-sm font-medium text-gray-700">Company Name</label>
//                                 <input
//                                     type="text"
//                                     value={company}
//                                     onChange={(e) => setCompany(e.target.value)}
//                                     className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                                 />
//                             </div>
//                             <div className="w-full lg:w-1/2 px-2 mb-4">
//                                 <label className="block text-sm font-medium text-gray-700">Email</label>
//                                 <input
//                                     type="email"
//                                     value={email}
//                                     onChange={(e) => setEmail(e.target.value)}
//                                     className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                                     required
//                                 />
//                             </div>
//                             <div className="w-full lg:w-1/2 px-2 mb-4">
//                                 <label className="block text-sm font-medium text-gray-700">Phone Number</label>
//                                 <input
//                                     type="tel"
//                                     value={phone}
//                                     onChange={(e) => setPhone(e.target.value)}
//                                     className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                                 />
//                             </div>
//                             <div className="w-full px-2 mb-4">
//                                 <label className="block text-sm font-medium text-gray-700">Subject</label>
//                                 <input
//                                     type="text"
//                                     value={subject}
//                                     onChange={(e) => setSubject(e.target.value)}
//                                     className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                                 />
//                             </div>
//                             <div className="w-full px-2 mb-4">
//                                 <label className="block text-sm font-medium text-gray-700">Message</label>
//                                 <CKEditor
//                                     editor={ClassicEditor}
//                                     data={message}
//                                     onChange={(event, editor) => {
//                                         const data = editor.getData();
//                                         setMessage(data);
//                                     }}
//                                 />
//                             </div>
//                             {/* <div className="w-full px-2 mb-4">
//                                 <label className="block text-sm font-medium text-gray-700">Message</label>
//                                 <textarea
//                                     value={message}
//                                     onChange={(e) => setMessage(e.target.value)}
//                                     className="mt-1 p-2 w-full h-40 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                                     required
//                                 />
//                             </div> */}
//                         </div>
//                         <button
//                             type="submit"
//                             className="w-full py-2 px-4 bg-orange-400 text-white font-bold rounded-md shadow-md hover:bg-orange-500 transition duration-200"
//                         >
//                             Send
//                         </button>
//                     </form>
//                 </div>
//                 <div className="hidden lg:block lg:w-1/2 relative">
//                     <img
//                         src={ContactImg}
//                         alt="Contact Illustration"
//                         className="w-full h-full object-cover rounded-r-lg"
//                     />
//                     <div className="absolute rounded-r-lg inset-x-0 bottom-0 bg-gray-900 bg-opacity-75 backdrop-filter backdrop-blur-lg p-6 text-white">
//                         <p className="text-lg font-semibold mb-2">Need assistance?</p>
//                         <p className="text-sm text-gray-200 mb-4">For inquiries, contact us at <span className="font-medium">(800) 472-7832</span> or email us at <span className="font-medium">support@sartec.com</span>.</p>
//                         <p className="text-xs text-gray-300">We're here to help!</p>
//                     </div>
//                 </div>
//             </div>

//             <div className="relative block lg:hidden inset-x-0 bottom-0 bg-gray-900 bg-opacity-25 backdrop-filter backdrop-blur-lg p-6 text-white">
//                 <p className="text-lg font-semibold mb-2">Need assistance?</p>
//                 <p className="text-sm text-gray-200 mb-4">For inquiries, contact us at <span className="font-medium">(800) 472-7832</span> or email us at <span className="font-medium">support@sartec.com</span>.</p>
//                 <p className="text-xs text-gray-300">We're here to help!</p>
//             </div>
//         </div>
//     );
// };

// export default ContactPage;



import React, { useState } from 'react';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import ContactImg from '../assets/img/subtle-cinematics-V4Mo8UYKRvY-unsplash.jpg';
import '../assets/ckeditor.css';


const ContactPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8000/api/contact/', {
                name,
                email,
                company,
                phone,
                subject,
                message,
            });
            if (response.status === 200) {
                setStatus('Message sent successfully');
                setName('');
                setEmail('');
                setCompany('');
                setPhone('');
                setSubject('');
                setMessage('');
            } else {
                setStatus('Failed to send message');
            }
        } catch (error) {
            setStatus('Failed to send message');
        }


        
    };

    return (
        <div className="min-h-fit flex-col lg:flex items-center justify-center">
            <div className="absolute inset-0 bg-cover bg-center opacity-80 lg:hidden" style={{ backgroundImage: `url(${ContactImg})` }}></div>
            <div className="bg-white p-4 rounded-lg opacity-90 shadow-md w-full max-w-1/2 lg:w-9/12 lg:opacity-100">
                <div className="w-full lg:w-full p-4 lg:mb-10">
                    <div className="mb-6 text-center">
                        <h1 className="text-3xl font-bold mb-2 text-gray-800">Contact Us</h1>
                        <p className="text-lg text-gray-600">We'd love to hear from you</p>
                    </div>
                    {status && <div className="mb-4 text-green-600">{status}</div>}
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="flex flex-wrap -mx-2">
                            <div className="w-full lg:w-1/2 px-2 mb-4">
                                <label className="block text-sm font-medium text-gray-700">Name</label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="John Doe"
                                    className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                    required
                                />
                            </div>
                            <div className="w-full lg:w-1/2 px-2 mb-4">
                                <label className="block text-sm font-medium text-gray-700">Company Name</label>
                                <input
                                    type="text"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    placeholder="SarTec Corporation"
                                    className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                    required
                                />
                            </div>
                            <div className="w-full lg:w-1/2 px-2 mb-4">
                                <label className="block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="office@sartec.com"
                                    className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                    required
                                />
                            </div>
                            <div className="w-full lg:w-1/2 px-2 mb-4">
                                <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                                <input
                                    type="tel"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="###-###-####"
                                    className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                    required
                                />
                            </div>
                            <div className="w-full px-2 mb-4">
                                <label className="block text-sm font-medium text-gray-700">Subject</label>
                                <input
                                    type="text"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    placeholder="Enter subject here..."
                                    className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                                    required
                                />
                            </div>
                            <div className="w-full px-2 mb-4">
                            <label className="block text-gray-700">Message</label>
                            <textarea
                            className="w-full h-32 p-2 border border-gray-300 rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Enter your message here..."
                            required
                            />
                            </div>
                            {/* <div className="w-full px-2 mb-4">
                                <label className="block text-gray-700">Message</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={message}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setMessage(data);
                                    }}
                                />
                            </div> */}
                        </div>
                        <button
                            type="submit"
                            className="w-full py-2 px-4 bg-orange-400 text-white font-bold rounded-md shadow-md hover:bg-orange-500 transition duration-200"
                        >
                            Send
                        </button>
                    </form>
                </div>
                <div className="relative lg:mt-4 bg-gray-900 bg-opacity-75 backdrop-filter backdrop-blur-lg p-6 text-white rounded-lg">
                    <p className="text-lg font-semibold mb-2">Need assistance?</p>
                    <p className="text-sm text-gray-200 mb-4">For inquiries, contact us at <span className="font-medium">(800) 472-7832</span> or email us at <span className="font-medium">office@sartec.com</span>.</p>
                    <p className="text-xs text-gray-300">We're here to help!</p>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;


