// // PercentageLiveGraph.js
// import React, { useEffect, useState } from 'react';
// import { Bar } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   BarElement,
//   CategoryScale,
//   LinearScale,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

// const PercentageLiveGraph = ({ percentageData }) => {
//   const [chartData, setChartData] = useState({
//     labels: [],
//     datasets: [
//       {
//         label: 'Percentage',
//         data: [],
//         backgroundColor: 'rgba(75,192,192,0.2)',
//         borderColor: 'rgba(75,192,192,1)',
//         borderWidth: 1,
//       },
//     ],
//   });
//   useEffect(() => {
//     if (percentageData) {
//       const newTime = new Date().toLocaleTimeString();
//       const newFlowRate = percentageData.mInc;

//       setChartData((prevData) => {
//         const updatedLabels = [...prevData.labels, newTime].slice(-10); // Keep last 10 data points
//         const updatedData = [...prevData.datasets[0].data, newFlowRate].slice(-10);

//         return {
//           labels: updatedLabels,
//           datasets: [
//             {
//               ...prevData.datasets[0],
//               data: updatedData,
//             },
//           ],
//         };
//       });
//     }
//   }, [percentageData]);

//   return (
//     <div>
//       <div style={{ textAlign: 'center', marginBottom: '0px' }}>
//         <h3>Something</h3>
//         {percentageData !== null && (
//           <p>
//             Current Value: <strong>{percentageData.mInc}%</strong>
//           </p>
//         )}
//       </div>
//       <Bar data={chartData} />
//     </div>
//   );
// };

// export default PercentageLiveGraph;


import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PercentageLiveGraph = ({ percentageData }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (percentageData) {
      setPercentage(percentageData.mInc);
    }
  }, [percentageData]);

  const options = {
    chart: {
      height: 300,
      type: 'radialBar',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
      },
      dropShadow: {
        enabled: true,
        top: 2,
        left: 2,
        blur: 1,
        opacity: 0.2,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
          
        },
        track: {
          background: '#E3E6FF', // Darker shade for the underside of the outer circle
          strokeWidth: '100%',
          opacity: 1,
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 5,
            left: 5,
            blur: 8,
            opacity: 0.7,
            color: '#303C9C',

          },
        },
        dataLabels: {
          name: {
            offsetY: -10,
            show: false,
            color: '#757FD5',
            fontSize: '14px',
            fontWeight: 'bold',
          },
          value: {
            offsetY: 5,
            fontSize: '24px',
            color: '#757FD5',
            fontWeight: 'bold',
            formatter: (val) => `${val}%`,
          },
        },
      },
    },
    fill: {
      type: 'solid',
      
      colors: ['#757FD5'],
    },
    stroke: {
      lineCap: 'round',
    },
    // labels: ['Incoming Moisture'],
  };

  const series = [percentage];

  return (
    <div className="text-center">
      <h3 className="text-xl font-semibold lg:text-xl mb-2 text-[#757FD5]">Incoming Moisture</h3>
      <ReactApexChart options={options} series={series} type="radialBar" height={300} />
    </div>
  );
};

export default PercentageLiveGraph;





// RealTimeGraph.js
// RealTimeGraph.js

// const RealTimeGraph = ({ realtimeData }) => {
//   const [chartData, setChartData] = useState({
//     labels: [],
//     datasets: [
//       {
//         label: 'Grain Flow Rate (Lbs/Min)',
//         data: [],
//         borderColor: 'rgba(75,192,192,1)',
//         backgroundColor: 'rgba(75,192,192,0.2)',
//         fill: true,
//       },
//     ],
//   });

//   useEffect(() => {
//     if (realtimeData) {
//       const newTime = new Date().toLocaleTimeString();
//       const newFlowRate = realtimeData.LbsMin;

//       setChartData((prevData) => {
//         const updatedLabels = [...prevData.labels, newTime].slice(-10); // Keep last 10 data points
//         const updatedData = [...prevData.datasets[0].data, newFlowRate].slice(-10);

//         return {
//           labels: updatedLabels,
//           datasets: [
//             {
//               ...prevData.datasets[0],
//               data: updatedData,
//             },
//           ],
//         };
//       });
//     }
//   }, [realtimeData]);

//   return (
//     <div>
//       <div style={{ textAlign: 'center', marginBottom: '0px' }}>
//         <h3>Grain Flow Rate</h3>
//         {realtimeData && (
//           <p>
//             Current Value: <strong>{realtimeData.LbsMin} Lbs/Min</strong>
//           </p>
//         )}
//       </div>
//       <Line data={chartData} />
//     </div>
//   );
// };

// export default RealTimeGraph;
