import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const RPMGraph = ({ percentageData, num, colors }) => {
  const [rate, setRate] = useState(0);

  useEffect(() => {
    if (percentageData) {
      if (num === "1"){
        setRate(percentageData.rpm1);
      }
      else{
        setRate(percentageData.rpm2);
      }
    }
  }, [percentageData]);

  const options = {
    chart: {
      height: 300,
      type: 'radialBar',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
      },
      dropShadow: {
        enabled: true,
        top: 2,
        left: 2,
        blur: 1,
        opacity: 0.2,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '60%',
        },
        track: {
          background: colors[0], // Darker shade for the underside of the outer circle
          strokeWidth: '100%',
          opacity: 1,
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 5,
            left: 5,
            blur: 8,
            opacity: 0.7,
            color: colors[1],
          },
        },
        dataLabels: {
          name: {
            offsetY: -10,
            show: false,
            color: colors[2],
            fontSize: '14px',
            fontWeight: 'bold',
          },
          value: {
            offsetY: 5,
            fontSize: '24px',
            color: colors[2],
            fontWeight: 'bold',
            //formatter: (val) => `${val}`, // Changed from `${val}%` to `${val} CC/Min`
            formatter: function (val) {
              return val + ' RPM'
            } // Changed from `${val}%` to `${val} CC/Min`
          },
        },
      },
    },
    fill: {
      type: 'solid',
      colors: colors[4],
    },
    stroke: {
      lineCap: 'round',
    },
    // labels: ['Incoming Moisture'],
  };

  const series = [rate]; // Use `rate` instead of `percentage`

  return (
    <div>
      <h3 className={"text-xl font-semibold lg:text-xl mb-2 text-[" + colors[2] + "]"}>Peg Feeder #{num} RPM</h3>
      <ReactApexChart options={options} series={series} type="radialBar" height={300} />
    </div>
  );
};

export default RPMGraph;
