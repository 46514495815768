// import React, { useEffect } from 'react';
import './App.css';
import './index.css';
import React from 'react';

// import axios from 'axios';
// import {FirestoreDataComponent} from './components/Firestore_test'; // Import FirestoreDataComponent
// import logo from './logo.svg';
import { GlobalStateProvider } from './components/GlobalStateContext';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import { Login } from './components/Login';
import { ProtectedComponent } from './components/Protected_Login';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import AboutPage from './components/AboutPage';
import SasiReports from './components/SasiReports';
import ContactPage from './components/ContactPage';
import SarCompPage from './components/SarComputerPage';
import SarTimePage from './components/SarTimePage';
import TmroPage from './components/TmroPage';


function App() {
  // useEffect(() => {
  //   axios.get('http://localhost:8000/api/hello-world/')
  //     .then(response => {
  //       console.log(response.data.message); 
  //     });
  // }, []);


  const [isSidebarOpen, setIsSidebarOpen] = React.useState(window.innerWidth >= 768);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleCollapse = () => {
      setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <Router>
        <GlobalStateProvider>
          <Routes>
              <Route path="/login" element={<Login />} />
              <Route 
                  path="/" 
                  element={
                      <Layout 
                          isSidebarOpen={isSidebarOpen} 
                          toggleSidebar={toggleSidebar} 
                          isSidebarCollapsed={isSidebarCollapsed} 
                          toggleCollapse={toggleCollapse} 
                      />
                  }
              >
                  <Route index element={<ProtectedRoute component={ProtectedComponent} />} />
                  <Route path="/about" element={<ProtectedRoute component={AboutPage} />} /> 
                  <Route path="/sasi" element={<ProtectedRoute component={SasiReports} />} />
                  <Route path="/contact" element={<ProtectedRoute component={ContactPage} />} />
                  <Route path="/sarcomp" element={<ProtectedRoute component={SarCompPage} />} />
                  <Route path="/sartime" element={<ProtectedRoute component={SarTimePage} />} />
                  <Route path="/tmro" element={<ProtectedRoute component={TmroPage} />} />
                  {/* <Route path="/otherpage" element={<ProtectedRoute component={OtherPage} />} /> */}
                  {/* Add other protected routes here */}
              </Route>
          </Routes>
        </GlobalStateProvider>
    </Router>
  );
}

//   return (
        // <Router>
        //     <div className="App">
        //         <Routes>
        //             <Route path="/" element={<Navigate to="/login" />} />
        //             <Route path="/login" element={<Login />} />
        //             <Route path="/protected" element={<ProtectedRoute component={ProtectedComponent} />} />
        //         </Routes>
        //     </div>
        // </Router>






    // <div className="App">
    //   <header className="App-header">
    //     <FirestoreDataComponent />
        
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
//   );
// }

export default App;
