import React, { useEffect, useState } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage, db } from '../firebase';
import { useGlobalContext } from './GlobalStateContext';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import LoadingAnimation from './LoadingAnimation';

const SasiReports = () => {
  const { selectedFeedlot, feedlots, handleFeedlotSelect } = useGlobalContext();
  const [pdfFiles, setPdfFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedFeedlot && selectedFeedlot.SarCompID) {
      fetchPdfFiles(selectedFeedlot.SarCompID);
    }
  }, [selectedFeedlot]);

  const fetchPdfFiles = async (sarCompID) => {
    setLoading(true);
    setError(null);
    const pdfList = [];
    try {
      const pdfDatesRef = collection(db, sarCompID, 'GeneralData', 'PDFDates');
      const pdfDatesQuery = query(pdfDatesRef, orderBy('date', 'desc')); // Ordering by the 'date' field
      const querySnapshot = await getDocs(pdfDatesQuery);

      querySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.name && data.date) {
          pdfList.push(data.name);
        }
      });

      const pdfUrls = await Promise.all(
        pdfList.map(async (pdfName) => {
          const storageRef = ref(storage, `System.${sarCompID}/${pdfName}`);
          const url = await getDownloadURL(storageRef);
          return { name: pdfName, url };
        })
      );

      setPdfFiles(pdfUrls);
    } catch (error) {
      console.error('Error fetching PDF files:', error);
      setError('Failed to fetch PDF files. Please check the console for more details.');
    }
    setLoading(false);
  };

  const handleFeedlotChange = (event) => {
    const selectedFeedlotIndex = event.target.value;
    const selectedFeedlot = feedlots[selectedFeedlotIndex];
    handleFeedlotSelect({ target: { value: selectedFeedlotIndex } });
  };

  const sarCompFeedlots = feedlots.filter(feedlot => feedlot.SarCompID);

  return (
<div className="flex flex-col items-center bg-gray-900 text-white p-6 rounded-lg shadow-lg max-w-fullxl mx-auto">
  <h1 className="text-3xl font-bold mb-6">Your SASI Reports</h1>
  
  <div className="w-full mb-4">
    <label htmlFor="feedlotSelect" className="block text-lg mb-2">Select a Feedlot</label>
    <select
      className="cursor-pointer w-full p-3 rounded-md bg-gray-700 text-gray-200 shadow focus:ring-2 focus:ring-slate-500" 
      onChange={handleFeedlotSelect}
      value={selectedFeedlot ? feedlots.findIndex(feedlot => (feedlot.SarCompID === selectedFeedlot.SarCompID && feedlot.TMRID === selectedFeedlot.TMRID)) : ''}
    >
        <option value="">-- Select a Feedlot --</option>
        {feedlots.map((feedlot, index) => (
          <option key={index} value={index}>
            {feedlot.Name || `Feedlot ${index + 1}`}
          </option>
        ))}
      </select>
  </div>

  {loading ? (
    <div className="col-span-2 lg:col-span-4 m-10 flex justify-center items-center">
      <LoadingAnimation />
    </div>
  ) : error ? (
    <p className="text-lg text-red-500">{error}</p>
  ) : (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full">
      {pdfFiles.map((file) => (
        <a
          key={file.name}
          href={file.url}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-gray-800 rounded-lg p-4 transition-transform transform hover:scale-105 hover:bg-gray-700 duration-300 flex items-center justify-center"
        >
          <div className="text-center text-white-400">
            {file.name}
          </div>
        </a>
      ))}
    </div>
  )}
</div>


  );
};

export default SasiReports;
