// import React from 'react';
// import Chart from 'react-apexcharts';

// const BatchGraph = ({ fieldsData, selectedField }) => {
//   const batchIds = fieldsData.map((data) => data.id);
//   const fieldValues = fieldsData.map((data) => Number(data[selectedField] || 0));

//   const chartOptions = {
//     chart: {
//       type: 'bar',
//     },
//     xaxis: {
//       categories: batchIds,
//       title: {
//         text: 'Batch ID',
//       },
//       style: {
//         colors: '#FFBE7D', 
//       },
//     },
//     yaxis: {
//       title: {
//         text: selectedField,
//       },
//       style: {
//         colors: '#FFBE7D', // Change y-axis label color
//       },
//     },
//     // tooltip: {
//     //   theme: 'dark', // Set tooltip theme to dark
//     // },
//     title: {
//       text: `Field: ${selectedField}`,
//       align: 'center',
//     },
//   };

//   const chartSeries = [
//     {
//       name: selectedField,
//       data: fieldValues,
//     },
//   ];

//   return (
//     <div className="w-full">
//       <Chart options={chartOptions} series={chartSeries} type="bar" height={350} />
//     </div>
//   );
// };

// export default BatchGraph;


// import React, { useState, useEffect, useContext } from 'react';
// import Chart from 'react-apexcharts';
// // import { GlobalStateContext } from './GlobalStateContext';

// const BatchGraph = ({ fieldsData, selectedField }) => {
//   const [seriesData, setSeriesData] = useState([]);
//   const [zoomConfig, setZoomConfig] = useState({});

//   // Function to sort batch IDs correctly
//   const sortBatches = (data) => {
//     return data.sort((a, b) => {
//       const aParts = a.id.split('_');
//       const bParts = b.id.split('_');
//       const aNum = parseInt(aParts[aParts.length - 1], 10);
//       const bNum = parseInt(bParts[bParts.length - 1], 10);
//       return aNum - bNum;
//     });
//   };

//   useEffect(() => {
//     const sortedData = sortBatches([...fieldsData]);
//     const dataSeries = sortedData.map((batch) => ({
//       x: batch.id,
//       y: Number(batch[selectedField]) || 0,
//     }));
//     setSeriesData([{ name: selectedField, data: dataSeries }]);

//     // Set zoom configuration based on the number of batches
//     if (sortedData.length > 15) {
//       setZoomConfig({
//         min: 0,
//         max: 9,
//       });
//     } else {
//       setZoomConfig({});
//     }
//   }, [fieldsData, selectedField]);

//   const options = {
//     chart: {
//       type: 'bar',
//       background: 'transparent',
//       zoom: {
//         enabled: true,
//         type: 'x', // Enable zooming along the x-axis
//         autoScaleYaxis: true, // Adjust y-axis automatically when zooming
//       },
//       toolbar: {
//         show: true,
//         tools: {
//           download: true,
//           selection: true,
//           zoom: true,
//           zoomin: true,
//           zoomout: true,
//           pan: true,
//           reset: true,
//         },
//       },
//       animations: {
//         enabled: true,
//         easing: 'easeinout',
//         speed: 800,
//         animateGradually: {
//           enabled: true,
//           delay: 150,
//         },
//         dynamicAnimation: {
//           enabled: true,
//           speed: 350,
//         },
//       },
//       events: {
//         beforeResetZoom: (ctx, opt) => {
//           if (seriesData[0]?.data.length > 15) {
//             return {
//               xaxis: {
//                 min: 0,
//                 max: 9,
//               },
//             };
//           } else {
//             return {};
//           }
//         },
//       },
//     },
//     colors: ['#F88945'],
//     xaxis: {
//       categories: seriesData[0]?.data.map((data) => data.x) || [],
//       tickPlacement: "on",
//       labels: {
//         style: {
//           colors: '#EAEAEA', // Light color for the text
//         },
//       },
//       min: zoomConfig.min,
//       max: zoomConfig.max,
//     },
//     yaxis: {
//       labels: {
//         style: {
//           colors: '#EAEAEA', // Light color for the text
//         },
//       },
//     },
//     title: {
//       text: `Field: ${selectedField}`,
//       style: {
//         color: '#EAEAEA', // Light color for the text
//       },
//     },
//     tooltip: {
//       theme: 'dark', // Set tooltip theme to dark
//     },
//   };


//   return (
//     <div className="w-full">
//       <Chart options={options} series={seriesData} type="bar" height={350} />
//     </div>
//   );
// };

// export default BatchGraph;


// import React, { useState, useEffect } from 'react';
// import ReactApexChart from 'react-apexcharts';

// const BatchGraph = ({ fieldsData, selectedField }) => {

//   const fieldUnits = {
//     Grain: 'lbs',
//     LSCAdded: 'oz',
//     WaterAdded: 'gal'
//   };

//   const [seriesData, setSeriesData] = useState({
//     series: [
//       {
//         name: `${selectedField} (${fieldUnits[selectedField]})`,
//         data: [],
//       },
//     ],
//     options: {
//       chart: {
//         type: 'bar',
//         background: 'transparent',
//         height: 350,
//         zoom: {
//           enabled: true,
//           type: 'x', // Enable zooming along the x-axis
//           autoScaleYaxis: true, // Adjust y-axis automatically when zooming
//         },
//         toolbar: {
//           show: true,
//           tools: {
//             download: true,
//             selection: true,
//             zoom: true,
//             zoomin: true,
//             zoomout: true,
//             pan: true,
//             reset: true,
//           },
//         },
//         animations: {
//           enabled: true,
//           easing: 'easeinout',
//           speed: 800,
//           animateGradually: {
//             enabled: true,
//             delay: 150,
//           },
//           dynamicAnimation: {
//             enabled: true,
//             speed: 350,
//           },
//         },
//         events: {
//           beforeResetZoom: (ctx, opt) => {
//             if (seriesData.series[0]?.data.length > 15) {
//               return {
//                 xaxis: {
//                   min: 0,
//                   max: 9,
//                 },
//               };
//             } else {
//               return {};
//             }
//           },
//         },
//       },
//       colors: ['#F88945'],
//       xaxis: {
//         categories: [],
//         tickPlacement: "on",
//         labels: {
//           style: {
//             colors: '#EAEAEA', // Light color for the text
//           },
//         },
//       },
//       yaxis: {
//         labels: {
//           style: {
//             colors: '#EAEAEA', // Light color for the text
//           },
//         },
//       },
//       title: {
//         text: `${selectedField} (${fieldUnits[selectedField]})`,
//         style: {
//           color: '#EAEAEA', // Light color for the text
//         },
//       },
//       tooltip: {
//         theme: 'dark', // Set tooltip theme to dark
//       },
//       responsive: [{
//         breakpoint: 1000,
//         options: {
//           chart: {
//             height: 300
//           },
//           xaxis: {
//             labels: {
//               style: {
//                 fontSize: '12px'
//               }
//             }
//           },
//           yaxis: {
//             labels: {
//               style: {
//                 fontSize: '12px'
//               }
//             }
//           }
//         }
//       }]
//     },
//   });

//   // Function to sort batch IDs correctly
//   const sortBatches = (data) => {
//     return data.sort((a, b) => {
//       const aParts = a.id.split('_');
//       const bParts = b.id.split('_');
//       const aNum = parseInt(aParts[aParts.length - 1], 10);
//       const bNum = parseInt(bParts[bParts.length - 1], 10);
//       return aNum - bNum;
//     });
//   };

//   useEffect(() => {
//     const sortedData = sortBatches([...fieldsData]);
//     const dataSeries = sortedData.map((batch) => ({
//       x: batch.id,
//       y: Number(batch[selectedField]) || 0,
//     }));
    
//     setSeriesData((prevData) => ({
//       ...prevData,
//       series: [{ name: `${selectedField} (${fieldUnits[selectedField]})`, data: dataSeries }],
//       options: {
//         ...prevData.options,
//         xaxis: {
//           ...prevData.options.xaxis,
//           categories: dataSeries.map(data => data.x),
//         },
//         title: {
//           ...prevData.options.title,
//           text: `${selectedField} (${fieldUnits[selectedField]})`
//         }
//       }
//     }));
//   }, [fieldsData, selectedField]);

//   return (
//     <div className="w-full h-full">
//       <div className="w-full overflow-auto">
//       {/* <h3 className='text-gray-200'>Field: {selectedField} ({fieldUnits[selectedField]})</h3> */}
//         <ReactApexChart options={seriesData.options} series={seriesData.series} type="bar" height={350} />
//       </div>
//     </div>
//   );
// };

// export default BatchGraph;




import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const BatchGraph = ({ fieldsData, selectedField }) => {
  const fieldUnits = {
    Grain: 'lbs',
    LSCAdded: 'oz',
    WaterAdded: 'gal',
  };

  const [seriesData, setSeriesData] = useState({
    series: [
      {
        name: `${selectedField} (${fieldUnits[selectedField]})`,
        data: [],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        background: 'transparent',
        height: 350,
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: true,
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      colors: ['#F88945'],
      xaxis: {
        categories: [],
        tickPlacement: 'on',
        labels: {
          style: {
            colors: '#EAEAEA',
            fontSize: '12px',
          },
          hideOverlappingLabels: true,
          maxHeight: 60,
        },
        title: {
          text: 'Batch Number',
          style: {
            color: '#EAEAEA',
          },
        },
        tickAmount: 10, // Control the number of ticks for readability
      },
      yaxis: {
        title: {
          text: `${selectedField} (${fieldUnits[selectedField]})`,
          style: {
            color: '#EAEAEA',
          },
        },
        labels: {
          style: {
            colors: '#EAEAEA',
          },
        },
      },
      title: {
        text: `${selectedField} (${fieldUnits[selectedField]})`,
        style: {
          color: '#EAEAEA',
        },
      },
      tooltip: {
        theme: 'dark',
      },
      dataLabels: {
        enabled: false, // Disable data labels by default
        style: {
          colors: ['#F3F4F6'],
        },
        offsetY: -10,
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              height: 300,
            },
            xaxis: {
              labels: {
                style: {
                  fontSize: '12px',
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: '12px',
                },
              },
            },
          },
        },
      ],
    },
  });

  const sortBatches = (data) => {
    return data.sort((a, b) => {
      const aParts = a.id.split('_');
      const bParts = b.id.split('_');
      const aNum = parseInt(aParts[aParts.length - 1], 10);
      const bNum = parseInt(bParts[bParts.length - 1], 10);
      return aNum - bNum;
    });
  };

  useEffect(() => {
    const sortedData = sortBatches([...fieldsData]);
    const dataSeries = sortedData.map((batch) => ({
      x: batch.id.removeChar(),
      //x: batch.id,
      y: Number(batch[selectedField]) || 0,
    }));

    setSeriesData((prevData) => {
      const isLargeDataset = dataSeries.length > 15; // Define what constitutes a large dataset
      return {
        ...prevData,
        series: [{ name: `${selectedField} (${fieldUnits[selectedField]})`, data: dataSeries }],
        options: {
          ...prevData.options,
          xaxis: {
            ...prevData.options.xaxis,
            categories: dataSeries.map((data) => data.x),
            tickAmount: Math.min(10, dataSeries.length),
          },
          dataLabels: {
            enabled: !isLargeDataset, // Disable data labels for large datasets
          },
          yaxis: {
            ...prevData.options.yaxis,
            title: {
              text: `${selectedField} (${fieldUnits[selectedField]})`,
              style: {
                color: '#EAEAEA',
              },
            },
          },
          title: {
            ...prevData.options.title,
            text: `${selectedField} (${fieldUnits[selectedField]})`,
          },
        },
      };
    });
  }, [fieldsData, selectedField]);

  return (
    <div className="w-full h-full">
      <div className="w-full overflow-auto">
        <ReactApexChart options={seriesData.options} series={seriesData.series} type="bar" height={350} />
      </div>
    </div>
  );
};

String.prototype.removeChar = function () {
  var tmp = this.split('_'); // convert to an array
 // extract batch number from batch id
  return tmp[1]; // reconstruct the string
}

export default BatchGraph;

  