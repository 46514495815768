// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import LoginImg from '../assets/img/subtle-cinematics-V4Mo8UYKRvY-unsplash.jpg'
import LogoImg from '../assets/img/SarTec Logo HR cropped.jpg'

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            // navigate('/protected');
            navigate('/');
        } catch (err) {
            setError(err.message);
        }
    };

    return (
    //   <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-500 to-gray-200"
    //       style={{backgroundImage: 'linear-gradient(10deg, rgba(68,29,7,1) 0%, rgba(176,115,53,1) 59%, rgba(255,179,56,1) 100%)'}}
    //       // style={{backgroundImage: 'linear-gradient(336deg, rgba(51,51,51,1) 0%, rgba(161,161,161,1) 59%, rgba(210,210,210,1) 100%)'}}
    //       >
    //   <div className="bg-white rounded-lg shadow-md w-full max-w-md lg:max-w-3xl lg:flex lg:items-center z-10 opacity-90 lg:opacity-100">
    //     <div className="w-full lg:w-1/2 p-4">
    //       <div className="mb-8">
    //         <h1 className="text-3xl font-bold text-center mb-2 text-gray-800">Welcome!</h1>
    //         <p className="text-lg text-center text-gray-600">Sign in to access your Dashboard</p>
    //       </div>
    //       <form onSubmit={handleLogin}>
    //         <div className="mb-4">
    //           <label htmlFor="email" className="block text-sm font-medium text-gray-700">
    //             Email:
    //           </label>
    //           <input
    //             type="email"
    //             id="email"
    //             value={email}
    //             onChange={(e) => setEmail(e.target.value)}
    //             className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
    //           />
    //         </div>
    //         <div className="mb-4">
    //           <label htmlFor="password" className="block text-sm font-medium text-gray-700">
    //             Password:
    //           </label>
    //           <input
    //             type="password"
    //             id="password"
    //             value={password}
    //             onChange={(e) => setPassword(e.target.value)}
    //             className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
    //           />
    //         </div>
    //         {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
    //         <button
    //           type="submit"
    //           className="w-full py-2 px-4 bg-orange-400 text-white font-bold rounded-md shadow-md hover:bg-orange-500 transition duration-200"
    //         >
    //           Login
    //         </button>
    //       </form>
    //     </div>
    //     <div className="hidden lg:block lg:w-1/2 relative">
    //       {/* Image */}
    //       <img
    //         src={LoginImg}
    //         alt="Login Illustration"
    //         className="w-full h-full object-cover rounded-r-lg"
    //     />

    //       {/* Bottom Section */}
    //       <div className="absolute inset-x-0 bottom-0 bg-gray-950 bg-opacity-65 backdrop-filter backdrop-blur-lg p-6 text-white">
    //         <p className="text-lg font-semibold mb-2">New to our dashboard?</p>
    //         <p className="text-sm text-gray-200 mb-4">For account inquiries, contact our office at <span className="font-medium">[Office Number]</span> or visit our <a href="#" className="text-blue-400 underline">website</a>.</p>
    //         <p className="text-xs text-gray-300">We're here to assist you!</p>
    //       </div>
    //     </div>
    //   </div>
    //   <div 
    //     style={{ backgroundImage: `url(${LoginImg})` }}
    //     className="absolute inset-0 bg-cover bg-center opacity-20 lg:hidden"
    //   >
    //   </div>
    //   <div className="block lg:hidden absolute inset-x-0 bottom-0 bg-gray-950 bg-opacity-65 backdrop-filter backdrop-blur-lg p-6 text-white">
    //         <p className="text-lg font-semibold mb-2">New to our dashboard?</p>
    //         <p className="text-sm text-gray-200 mb-4">For account inquiries, contact our office at <span className="font-medium">[Office Number]</span> or visit our <a href="#" className="text-blue-400 underline">website</a>.</p>
    //         <p className="text-xs text-gray-300">We're here to assist you!</p>
    //       </div>
    // </div>





      
    <div className="min-h-screen flex items-center justify-center" style={{backgroundImage: 'linear-gradient(10deg, rgba(68,29,7,1) 0%, rgba(176,115,53,1) 59%, rgba(255,179,56,1) 100%)'}}>

      <div className="bg-white rounded-lg shadow-md w-full max-w-md lg:max-w-3xl lg:flex lg:items-center z-10 opacity-90 lg:opacity-100">
    {/* Left Section - Sign In Form */}
    <div className="w-full lg:w-1/2 p-4 lg:mb-20">
      <div className="mb-4">
      <img
      src={LogoImg} // Replace with your logo's URL or path
      alt="Logo"
      className="w-56 mx-auto mb-12"
    />
        <h1 className="text-3xl font-bold text-center mb-2 text-gray-800">Welcome!</h1>
        <p className="text-lg text-center text-gray-600">Sign in to access your Dashboard</p>
      </div>
      <form onSubmit={handleLogin}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Password:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <button
          type="submit"
          className="w-full py-2 px-4 bg-orange-400 text-white font-bold rounded-md shadow-md hover:bg-orange-500 transition duration-200"
        >
          Login
        </button>
      </form>
    </div>

    {/* Right Section - Illustration and Information */}
    <div className="hidden lg:block lg:w-1/2 relative">
      {/* Image */}
      <img
        src={LoginImg}
        alt="Login Illustration"
        className="w-full h-full object-cover rounded-r-lg"
      />

      {/* Bottom Section */}
      <div className="absolute rounded-r-lg inset-x-0 bottom-0 bg-gray-900 bg-opacity-75 backdrop-filter backdrop-blur-lg p-6 text-white">
        <p className="text-lg font-semibold mb-2">New to our dashboard?</p>
        <p className="text-sm text-gray-200 mb-4">For account inquiries, contact our office at <span className="font-medium">(800) 472-7832</span> or email us at <span className="font-medium">office@sartec.com</span>.</p>
        <p className="text-xs text-gray-300">We're here to assist you!</p>
      </div>
    </div>
  </div>

  {/* Background Overlay for Smaller Screens */}
  <div className="absolute inset-0 bg-cover bg-center opacity-20 lg:hidden" style={{ backgroundImage: `url(${LoginImg})` }}></div>

  {/* Bottom Section for Smaller Screens */}
  <div className="block lg:hidden absolute inset-x-0 bottom-0 bg-gray-900 bg-opacity-75 backdrop-filter backdrop-blur-lg p-6 text-white">
    <p className="text-lg font-semibold mb-2">New to our dashboard?</p>
    <p className="text-sm text-gray-200 mb-4">For account inquiries, contact our office at <span className="font-medium">(800) 472-783]</span> or email us at <span className="font-medium">support@sartec.com</span>.</p>
    <p className="text-xs text-gray-300">We're here to assist you!</p>
  </div>
</div>

    );
}

export { Login };
