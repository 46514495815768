// src/firebase.js
// import firebase from 'firebase/app';
// import 'firebase/auth';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyCGzN-ht_It9RdomtalYCKF4ZxaBuFM2nE",
  authDomain: "sartecap-2b9c6.firebaseapp.com",
  databaseURL: "https://sartecap-2b9c6.firebaseio.com",
  projectId: "sartecap-2b9c6",
  storageBucket: "sartecap-2b9c6.appspot.com",
  messagingSenderId: "735344638846",
  appId: "1:735344638846:web:8d6833474e25db6b8a512f",
  measurementId: "G-PTC26DTXG5"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth };
export { db };
export { storage };

// firebase.initializeApp(firebaseConfig);

// export const auth = firebase.auth();
// export default firebase;
