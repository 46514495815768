// RealTimeGraph.js
// RealTimeGraph.js
// import React, { useEffect, useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   LineElement,
//   PointElement,
//   LinearScale,
//   Tooltip,
//   Title,
//   CategoryScale,
// } from 'chart.js';

// ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, CategoryScale);

// const RealTimeGraph = ({ realtimeData }) => {
//   const [chartData, setChartData] = useState({
//     labels: [],
//     datasets: [
//       {
//         label: 'Grain Flow Rate (Lbs/Min)',
//         data: [],
//         borderColor: 'rgba(75,192,192,1)',
//         backgroundColor: 'rgba(75,192,192,0.2)',
//         fill: true,
//       },
//     ],
//   });

//   useEffect(() => {
//     if (realtimeData) {
//       const newTime = new Date().toLocaleTimeString();  
//       const newFlowRate = realtimeData.LbsMin;

//       setChartData((prevData) => {
//         const updatedLabels = [...prevData.labels, newTime].slice(-10); // Keep last 10 data points
//         const updatedData = [...prevData.datasets[0].data, newFlowRate].slice(-10);

//         return {
//           labels: updatedLabels,
//           datasets: [
//             {
//               ...prevData.datasets[0],
//               data: updatedData,
//             },
//           ],
//         };
//       });
//     }
//   }, [realtimeData]);

//   return (
//     <div>
//       <div style={{ textAlign: 'center', marginBottom: '0px' }}>
//         <h3>Grain Flow Rate</h3>
//         {realtimeData && (
//           <p>
//             Current Value: <strong>{realtimeData.LbsMin} Lbs/Min</strong>
//           </p>
//         )}
//       </div>
//       <Line data={chartData} />
//     </div>
//   );
// };

// export default RealTimeGraph;

import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

// const RealTimeGraph = ({ realtimeData }) => {
//   const [chartOptions, setChartOptions] = useState({
//     options: {
//       chart: {
//         id: 'realtime-chart',
//         toolbar: {
//           show: true,
//         },
//       },
//       xaxis: {
//         type: 'datetime',
//         labels: {
//           style: {
//             colors: '#aaa', // Lighter color for y-axis labels
//           },
//         },
        
//       },
//       legend: {
//         show: true, // Show the legend
//         labels: {
//           colors: '#FFFFFF', // Change this to your desired legend color
//         },
//       },
//       yaxis: {
//         labels: {
//           style: {
//             colors: '#aaa', // Lighter color for y-axis labels
//           },
//         },
//       },
//       tooltip: {
//         theme: 'dark', // Set the tooltip theme (light or dark)
//         style: {
//           fontSize: '12px', // Customize font size
//           fontFamily: 'Arial', // Customize font family
//         },
//     },
//       fill: {
//         type: 'solid',
//         opacity: 0.3,
//       },
      
//       colors: ['#4bc0c0'],
//     },
    
    
//     series: [{
//       name: 'Grain Flow Rate (Lbs/Min)',
//       data: [],
//     }],
//   });

//   useEffect(() => {
//     if (realtimeData) {
//       const newTime = new Date().getTime();  
//       const newFlowRate = realtimeData.LbsMin;

//       setChartOptions((prevOptions) => ({
//         ...prevOptions,
//         series: [{
//           ...prevOptions.series[0],
//           data: [...prevOptions.series[0].data, [newTime, newFlowRate]].slice(-10), // Keep last 10 data points
//         }],
//       }));
//     }
//   }, [realtimeData]);

//   return (
//     <div>
//       <div style={{ textAlign: 'center', marginBottom: '0px' }}>
//         <h3>Grain Flow Rate</h3>
//         {realtimeData && (
//           <p>
//             Current Value: <strong>{realtimeData.LbsMin} Lbs/Min</strong>
//           </p>
//         )}
//       </div>
//       <Chart options={chartOptions.options} series={chartOptions.series} type="area" height={350} />
//     </div>
//   );
// };

const RealTimeGraph = ({ realtimeData }) => {
  const [chartOptions, setChartOptions] = useState({
    options: {
      chart: {
        id: 'realtime-chart',
        toolbar: {
          show: true, // Hide the chart toolbar
        },
        animations: {
          enabled: true, // Enable animations for smoother transitions
          easing: 'easeinout', // Set easing for animations
          speed: 500, // Set animation speed
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          style: {
            colors: '#FFBE7D', // Change x-axis label color
          },
          formatter: (value) => {
            const date = new Date(value);
            return date.toLocaleTimeString(); // Format x-axis labels to show only local time
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#FFBE7D', // Change y-axis label color
          },
        },
        //min: realtimeData ? Math.max(realtimeData.LbsMin, realtimeData.wAct) / 1.2 : 10,
        min: 0,
        //max: realtimeData ? Math.max(realtimeData.LbsMin, realtimeData.wAct) * 1.2 : 10, // Set the maximum value on the y-axis
      },
      tooltip: {
        theme: 'dark', // Set tooltip theme to dark
        x: {
          formatter: (value) => {
            const date = new Date(value);
            return date.toLocaleTimeString(); // Format tooltip to show only local time
          },
        },
      },
      dataLabels: {
        enabled: true, // Disable data labels
      },
      stroke: {
        width: 2, // Set stroke width
        curve: 'smooth', // Set curve style
      },
      fill: {
        type: 'solid', // Set fill type to gradient
        opacity: '.3',
        // gradient: {
        //   shadeIntensity: 1,
        //   opacityFrom: 0.7,
        //   opacityTo: 0.3,
        //   stops: [0, 100],
        // },
      },
      colors: ['#F88945'],
    },
    series: [{
      name: 'Grain Flow Rate (Lbs/Min)',
      data: [],
    }],
  });

  useEffect(() => {
    if (realtimeData) {
      const newTime = new Date().getTime();
      const newFlowRate = realtimeData.LbsMin;

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [{
          ...prevOptions.series[0],
          data: [...prevOptions.series[0].data, [newTime, newFlowRate]].slice(-20),
        }],
      }));
    }
  }, [realtimeData]);

  return (
    <div className=" rounded-lg ">
      <h3 className="text-lg font-semibold text-center mb-4 text-gray-200">Grain Flow Rate</h3>
      {realtimeData && (
        <p className="text-sm text-center text-gray-200">
          Current Value: <strong>{realtimeData.LbsMin} Lbs/Min</strong>
        </p>
      )}
      <Chart options={chartOptions.options} series={chartOptions.series} type="area" height={350} />
    </div>
  );
};


export default RealTimeGraph;






// // RealTimeGraph.js
// import React, { useEffect, useState } from 'react';
// import { Bar } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   BarElement,
//   PointElement,
//   LinearScale,
//   Title,
//   CategoryScale,
// } from 'chart.js';

// ChartJS.register(BarElement, PointElement, LinearScale, Title, CategoryScale);

// const RealTimeGraph = ({ realtimeData }) => {
//   const [chartData, setChartData] = useState({
//     labels: [],
//     datasets: [
//       {
//         label: 'Grain Flow Rate (Lbs/Min)',
//         data: [],
//         backgroundColor: 'rgba(75,192,192,0.2)',
//         borderColor: 'rgba(75,192,192,1)',
//         borderWidth: 1,
//       },
//     ],
//   });

//   useEffect(() => {
//     if (realtimeData) {
//       const newTime = new Date().toLocaleTimeString();
//       const newFlowRate = realtimeData.LbsMin;

//       setChartData((prevData) => {
//         const updatedLabels = [...prevData.labels, newTime].slice(-10); // Keep last 10 data points
//         const updatedData = [...prevData.datasets[0].data, newFlowRate].slice(-10);

//         return {
//           labels: updatedLabels,
//           datasets: [
//             {
//               ...prevData.datasets[0],
//               data: updatedData,
//             },
//           ],
//         };
//       });
//     }
//   }, [realtimeData]);

//   return (
//     <div>
//       <div style={{ textAlign: 'center', marginBottom: '0px' }}>
//         <h3>Grain Flow Rate</h3>
//         {realtimeData && (
//           <p>
//             Current Value: <strong>{realtimeData.LbsMin} Lbs/Min</strong>
//           </p>
//         )}
//       </div>
//       <Bar data={chartData} />
//     </div>
//   );
// };

// export default RealTimeGraph;





// import React, { useEffect, useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   LineElement,
//   PointElement,
//   LinearScale,
//   Title,
//   CategoryScale,
// } from 'chart.js';

// ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

// const RealTimeGraph = ({ realtimeData, setupRealtimeListener }) => {
//     const [chartData, setChartData] = useState({
//       labels: [],
//       datasets: [
//         {
//           label: 'Grain Flow Rate (Lbs/Min)',
//           data: [],
//           borderColor: 'rgba(75,192,192,1)',
//           backgroundColor: 'rgba(75,192,192,0.2)',
//           fill: true,
//         },
//       ],
//     });
  
//     // Load previous data from the database on component mount
//     useEffect(() => {
//       const fetchData = async () => {
//         try {
//           const data = await setupRealtimeListener();
//           if (data) {
//             const { timestamps, values } = data;
//             setChartData({
//               labels: timestamps,
//               datasets: [
//                 {
//                   ...chartData.datasets[0],
//                   data: values,
//                 },
//               ],
//             });
//           }
//         } catch (error) {
//           console.error('Error fetching previous data:', error);
//         }
//       };
  
//       fetchData();
//     }, [setupRealtimeListener]);
  
//     useEffect(() => {
//       if (realtimeData) {
//         const newTime = new Date().toLocaleTimeString();
//         const newFlowRate = realtimeData.LbsMin;
  
//         setChartData((prevData) => {
//           const updatedLabels = [...prevData.labels, newTime].slice(-10); // Keep last 10 data points
//           const updatedData = [...prevData.datasets[0].data, newFlowRate].slice(-10);
  
//           return {
//             labels: updatedLabels,
//             datasets: [
//               {
//                 ...prevData.datasets[0],
//                 data: updatedData,
//               },
//             ],
//           };
//         });
//       }
//     }, [realtimeData]);
  
//     return (
//       <div className="flex items-center justify-center">
//         <div className="w-full max-w-lg">
//           <div className="text-center mb-4">
//             <h3 className="text-lg font-bold">Real-Time Grain Flow Rate</h3>
//             {realtimeData && (
//               <p className="text-xs">
//                 Current Value: <strong className="text-sm">{realtimeData.LbsMin} Lbs/Min</strong>
//               </p>
//             )}
//           </div>
//           <div className="bg-white rounded-lg shadow-md p-4">
//             <Line
//               data={chartData}
//               options={{
//                 maintainAspectRatio: false, // This allows the chart to adjust its size based on the container
//                 responsive: true, // This ensures the chart is responsive
//                 plugins: {
//                   legend: {
//                     display: false, // Hide legend
//                   },
//                 },
//                 scales: {
//                   x: {
//                     ticks: {
//                       callback: function (value) {
//                         if (typeof value === 'string') {
//                           return value.split(' ')[0]; // Display only the time part
//                         }
//                         return value;
//                       },
//                     },
//                   },
//                   y: {
//                     ticks: {
//                       font: {
//                         size: 10, // Adjust the font size of y-axis ticks
//                       },
//                     },
//                   },
//                 },
//               }}
//             />
//           </div>
//         </div>
//       </div>
//     );
//   };
  
//   export default RealTimeGraph;