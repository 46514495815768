import React, { useState } from 'react';
import { useGlobalContext } from './GlobalStateContext';

const AboutPage = () => {
  const { userData } = useGlobalContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setActiveSection(sectionId);
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-900 text-white md:pl-4">
      {/* Mobile Menu Toggle Button */}
      <div className="lg:hidden p-4">
        <button
          onClick={toggleMenu}
          className="bg-gray-800 p-2 rounded-md focus:outline-none"
        >
          {menuOpen ? 'Close Menu' : 'Open Menu'}
        </button>
      </div>

      {/* Side Menu for Mobile */}
      {menuOpen && (
        <div className="lg:hidden bg-gray-800 p-4 rounded-xl">
          <nav className="space-y-4">
            <button
              onClick={() => handleScroll('introduction')}
              className={`block p-2 rounded ${activeSection === 'introduction' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
            >
              Introduction
            </button>
            <div className="space-y-2 pl-4">
              <button
                onClick={() => handleScroll('usage')}
                className={`block p-2 rounded ${activeSection === 'usage' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
              >
                Usage
              </button>
            </div>
            <button
              onClick={() => handleScroll('homePage')}
              className={`block p-2 rounded ${activeSection === 'homePage' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
            >
              Home Page
            </button>
            <button
              onClick={() => handleScroll('sarComputer')}
              className={`block p-2 rounded ${activeSection === 'sarComputer' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
            >
              SarComputer Page
            </button>
            <button
              onClick={() => handleScroll('tmro')}
              className={`block p-2 rounded ${activeSection === 'tmro' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
            >
              TMRO Page
            </button>
            <button
              onClick={() => handleScroll('sarTime')}
              className={`block p-2 rounded ${activeSection === 'sarTime' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
            >
              SarTime Page
            </button>
            <button
              onClick={() => handleScroll('sasi')}
              className={`block p-2 rounded ${activeSection === 'sasi' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
            >
              Sasi Reports Page
            </button>
            <button
              onClick={() => handleScroll('faq')}
              className={`block p-2 rounded ${activeSection === 'faq' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
            >
              FAQ
            </button>
          </nav>
        </div>
      )}

      {/* Side Menu for Desktop */}
      <div className="hidden lg:block lg:w-52 bg-gray-800 p-4 rounded-xl sticky top-0 h-fit shadow-3xl">
        <nav className="space-y-4">
          <button
            onClick={() => handleScroll('introduction')}
            className={`block p-2 rounded ${activeSection === 'introduction' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
          >
            Introduction
          </button>
          <div className="space-y-2 pl-4">
            <button
              onClick={() => handleScroll('usage')}
              className={`block p-2 rounded ${activeSection === 'usage' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
            >
              Usage
            </button>
          </div>
          <button
            onClick={() => handleScroll('homePage')}
            className={`block p-2 rounded ${activeSection === 'homePage' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
          >
            Grain Conditioner Live
          </button>
          <button
            onClick={() => handleScroll('sarComputer')}
            className={`block p-2 rounded ${activeSection === 'sarComputer' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
          >
            Grain Conditioner
          </button>
          <button
            onClick={() => handleScroll('tmro')}
            className={`block p-2 rounded ${activeSection === 'tmro' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
          >
            TMR Optimizer
          </button>
          {/* <button
            onClick={() => handleScroll('sarTime')}
            className={`block p-2 rounded ${activeSection === 'sarTime' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
          >
            SarTime Page
          </button> */}
          <button
            onClick={() => handleScroll('sasi')}
            className={`block p-2 rounded ${activeSection === 'sasi' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
          >
            Sasi Reports Page
          </button>
          <button
            onClick={() => handleScroll('faq')}
            className={`block p-2 rounded ${activeSection === 'faq' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
          >
            FAQ
          </button>
        </nav>
      </div>

      <div className="flex-1 pl-4 pr-4 pb-4 space-y-12">
  {/* Introduction Section */}
  <section id="introduction" className="bg-slate-800 shadow-3xl rounded-lg p-6 mb-8">
    <h1 className="text-4xl font-bold mb-4">Introduction</h1>
    <p className="text-lg">
      Welcome, {userData ? userData.Name : 'User'}! This dashboard provides a comprehensive view of real-time data from various feedlots. Below, you can find detailed information about each section and function available on the main home page.
    </p>
  </section>

  {/* Usage Section */}
  <section id="usage" className="bg-slate-800 shadow-3xl rounded-lg p-6 mb-8">
    <h2 className="text-3xl font-bold mb-4">Usage</h2>
    <p className="text-lg">
      Instructions on how to use the dashboard and make the most out of its features.
    </p>
  </section>

  {/* Home Page Section */}
  <section id="homePage" className="bg-slate-800 shadow-3xl rounded-lg p-6 mb-8">
    <h2 className="text-3xl font-bold mb-6">Grain Conditioner Live</h2>
    <p className="text-lg mb-6">
      Welcome to the main homepage section. Here, you can monitor and analyze real-time data relevant to your operations.
    </p>
    
    <div className="space-y-6">
      <div>
        <h3 className="text-2xl font-semibold mb-2">Grain</h3>
        <p className="text-lg">
          Check the type of grain currently in use, the flow rate at which grain is being processed, and the total amount of grain processed today (in pounds). The data is displayed on a graph for better visualization.
        </p>
      </div>
      
      <div>
        <h3 className="text-2xl font-semibold mb-2">Moisture</h3>
        <p className="text-lg">
          View live data of the moisture being processed. The graph provides a visual representation of the target water versus the actual water levels.
        </p>
      </div>
      
      <div>
        <h3 className="text-2xl font-semibold mb-2">SarTemp SG</h3>
        <p className="text-lg">
          Monitor the tank volume of your SarTemp and the flow rate. The data helps in managing and optimizing the SarTemp volume.
        </p>
      </div>
    </div>
  </section>

  {/* SarComputer Page Section */}
  <section id="sarComputer" className="bg-slate-800 shadow-3xl rounded-lg p-6 mb-8">
    <h2 className="text-3xl font-bold mb-4">Grain Conditioner</h2>
    <p className="text-lg">
      This is where you can check and compare different fields throughout 7 days. Just click on the dropdown menu and click on a field to display on the graph.
    </p>
  </section>

  {/* TMRO Page Section */}
  <section id="tmro" className="bg-slate-800 shadow-3xl rounded-lg p-6 mb-8">
    <h2 className="text-3xl font-bold mb-4">TMR Optimizer Page</h2>
    <p className="text-lg">
      Here you can check your individual data graph shown of Grain, Water, and LSCAdded, as well as the inventory grain of each individual graph on each batch in the second graph below. On the bottom, you can also see the total amount of all total batches, as well as clicking a single individual batch to check its individual data available.
    </p>
  </section>

  {/* SarTime Page Section
  <section id="sarTime" className="bg-slate-800 shadow-3xl rounded-lg p-6 mb-8">
    <h2 className="text-3xl font-bold mb-4">SarTime Page</h2>
    <p className="text-lg">
      Currently under construction.
    </p>
  </section> */}

  {/* Sasi Reports Page Section */}
  <section id="sasi" className="bg-slate-800 shadow-3xl rounded-lg p-6 mb-8">
    <h2 className="text-3xl font-bold mb-4">Sasi Reports Page</h2>
    <p className="text-lg">
      This is where you can look at each different PDF file available to your feedlot. These Sasi Reports allow you to look at a more in-depth history of your grain processing.
    </p>
  </section>

  {/* FAQ Section */}
  <section id="faq" className="bg-slate-800 shadow-3xl rounded-lg p-6 mb-8">
    <h2 className="text-3xl font-bold mb-4">FAQ</h2>
    <p className="text-lg">
      Frequently Asked Questions and their answers.
    </p>
  </section>
</div>

    </div>
  );
};

export default AboutPage;
