// Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

function Layout({ isSidebarOpen, toggleSidebar, isSidebarCollapsed, toggleCollapse }) {
    const styles = {
        container: {
            display: 'flex',
            height: '100vh',
            overflow: 'hidden',
            fontFamily: 'Arial, sans-serif',
        },
        content: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        },
        mainContent: {
            flex: 1,
            overflowY: 'auto',
            // padding: '1rem',
        },
    };

    return (
        <div style={styles.container} className='bg-gray-900'>
            <Sidebar isOpen={isSidebarOpen} isCollapsed={isSidebarCollapsed} toggleSidebar={toggleSidebar} toggleCollapse={toggleCollapse} />
            <div style={styles.content}>
                <Navbar toggleSidebar={toggleSidebar} />
                <main style={styles.mainContent}>
                    <Outlet />
                </main>
            </div>
        </div>
    );
}

export default Layout;
