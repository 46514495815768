// src/AboutPage.js
import React, { useContext } from 'react';
import { GlobalStateContext } from './GlobalStateContext';
import GraphSelector from '../components/AveragesGraphSelect';
import { Link } from 'react-router-dom';

const SarCompPage = () => {

    const {
        feedlots,
        handleFeedlotSelect,
        selectedFeedlot
    } = useContext(GlobalStateContext);


    
    return (
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 bg-gray-900 h-max p-3">
            {/* Welcome Section */}
            <div className="col-span-2 md:col-span-2 lg:col-span-4 bg-gradient-to-r from-#2f4858 to-#33658a p-6 rounded-lg shadow-3xl text-white" style={{ backgroundImage: 'linear-gradient(10deg, #2f4858 0%, #33658a 50%, #86bbd8 100%)' }}>
                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
                    <div>
                        <h1 className="text-3xl font-bold mb-2 lg:mb-0">SarComputer Dashboard</h1>
                        <p className="text-lg">Explore detailed graphs and historical data for each component, including insights from the past 7 days.</p>
                    </div>
                     <div className="mt-4 lg:mt-0 shadow-3xl rounded-lg">
                     <Link to="/about">
      <button className="bg-[#33658a] text-#2f4858 px-4 py-2 rounded-md shadow-xl hover:bg-[#2f4858] transition">
        Learn More
      </button>
    </Link>
                    </div>
                </div>
            </div>

            {/* Feedlot Selection */}
            {feedlots.length > 0 && (
                <div className="col-span-2 lg:col-span-4 bg-slate-400  p-4 rounded-lg shadow-3xl">
                    <h2 className="text-xl font-semibold text-gray-100 mb-4 ">Select a Feedlot:</h2>
                    <select 
                    className="cursor-pointer w-full p-2 rounded-md bg-slate-100 text-gray-700 shadow-3xl" 
                    onChange={handleFeedlotSelect}
                    value={selectedFeedlot ? feedlots.findIndex(feedlot => (feedlot.SarCompID === selectedFeedlot.SarCompID && feedlot.TMRID === selectedFeedlot.TMRID)) : ''}
                  >
                        <option value="">-- Select a Feedlot --</option>
                            {feedlots.map((feedlot, index) => (
                        <option key={index} value={index}>
                            {feedlot.Name || `Feedlot ${index + 1}`}
                        </option>
                        ))}
                    </select>
                </div>
            )}

            {/* Weekly Average Data Selection */}
            <div className='col-span-2 lg:col-span-4 bg-slate-400  p-4 rounded-lg shadow-3xl'>
                <div className=''>
                    <GraphSelector />
                </div>
            </div>
        </div>
    );
};

export default SarCompPage;
