import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const WaterChart = ({ realtimeData }) => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Target Water',
        data: [],
      },
      {
        name: 'Actual Water',
        data: [],
      },
    ],
    options: {
      chart: {
        type: 'area',
        height: 300,
      },
      dataLabels: {
        enabled: true,
        
      },
      stroke: {
        width: 2, // Set stroke width
        curve: 'smooth', // Set curve style
      },
      xaxis: {
        type: 'datetime',
        labels: {
            style: {
              colors: '#aaa', // Lighter color for x-axis labels
            },
            formatter: (value) => {
              const date = new Date(value);
              return date.toLocaleTimeString(); // Format x-axis labels to show only local time
            },
          },  
      },
      yaxis: {
        min: 0,
        labels: {
          style: {
            colors: '#aaa', // Lighter color for y-axis labels
          },
        },
      },
      legend: {
        position: 'bottom', // Position the legend
        labels: {
          colors: '#FFFFFF', // Change this to your desired legend color
        },
      },
      tooltip: {
        theme: 'dark', // Set the tooltip theme (light or dark)
        style: {
          fontSize: '12px', // Customize font size
          fontFamily: 'Arial', // Customize font family
        },
    },
      colors: ['#FF4560', '#4bc0c0'], // Custom colors for series
      
    
    },
  });

//   useEffect(() => {
//     if (realtimeData) {
//       const newTime = new Date().getTime(); // Use timestamp
//       const newTargetWater = realtimeData.wTarg;
//       const newActualWater = realtimeData.wAct;

//       setChartData((prevData) => ({
//         ...prevData,
//         series: [
//           {
//             name: 'Target Water',
//             data: [...prevData.series[0].data, [newTime, newTargetWater]],
//           },
//           {
//             name: 'Actual Water',
//             data: [...prevData.series[1].data, [newTime, newActualWater]],
//           },
//         ],
//       }));
//     }
//   }, [realtimeData]);

useEffect(() => {
    if (realtimeData) {
      const newTime = new Date().getTime(); // Use timestamp
      const newTargetWater = realtimeData.wTarg;
      const newActualWater = realtimeData.wAct;

      setChartData((prevData) => {
        const updatedTargetData = [...prevData.series[0].data, [newTime, newTargetWater]].slice(-10); // Keep last 10 data points
        const updatedActualData = [...prevData.series[1].data, [newTime, newActualWater]].slice(-10);

        return {
          ...prevData,
          series: [
            {
              name: 'Target Water',   
              data: updatedTargetData,
            },
            {
              name: 'Actual Water',
              data: updatedActualData,
            },
          ],
        };
      });
    }
  }, [realtimeData]);

  return (
    <div>
      <h3 className='text-gray-200'>Target Water Vs. Actual Water</h3>
      {realtimeData && (
        <p className='text-gray-200'>
          Target Water: {realtimeData.wTarg} GPM
          <br />
          Actual Water: {realtimeData.wAct} GPM
        </p>
      )}
      <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} />
    </div>
  );
};

export default WaterChart;
